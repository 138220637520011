import {Component} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import main from "./kaveer1.jpg";
import Blog from "./blog.component";


export default class Home extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render(){
        return(<div className="container-fluid px-0" style={{"background-color":"#DFBBB1","background-size": "cover"}}>
        <title>Kaveer Shah's Website</title>
        <a href="/admin/blogs"><button className="hiddenbutton">Access</button></a>
        <h2 className="title"> Welcome to <span className="span2">Kaveer Shah</span> (dot) com </h2> 
        <h3 style={{"color":"#FFF"}} >I was just born on 12/15/2023 and am the newest member of my family....</h3>
        <img className="center" src={main} width="200" alt="I am and can walk!!!Ultimate freedom..."/>
        <Blog/>
    </div>);
    }

    }